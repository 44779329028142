import React,{useEffect} from 'react';
import { Link } from 'react-router-dom';
import { slide as MenuBurger } from 'react-burger-menu'

import { MenuContext } from "../component/ContextProvider/MenuContext";
let prefixhome="/"

let menuitems=[{url:prefixhome,text:"CARROZZERIA",title:"title",active:true},{url:"/servizi",text:"SERVIZI",title:"title",active:false},{url:"/gallery",text:"GALLERY",title:"title",active:false},{url:"/contatti",text:"CONTATTI",title:"title",active:false},]

function Menu(props) {
    
    let { state, dispatch } = React.useContext(MenuContext);
    useEffect(()=>{
        dispatch({type:"set",payload:window.location.pathname})
    },[])
    return (
        <>
        <div id="menu">
            <div className="container">
                <div className="row">
                {menuitems.map((menu)=>{
                    return(
                        <div className="col-3 d-none d-sm-block">
                        <Link to={menu.url} onClick={()=>{dispatch({type:"set",payload:menu.url})}} className={state.item===menu.url ? "menuactive" :""}> {menu.text}</Link>
                        </div>
                        
                    )
                })}
                </div>
            </div>
        </div>
        <MenuBurger className={"d-block d-sm-none"}>
            {menuitems.map((menu)=>{
                    return(
                        <div className="col-12">
                        <Link to={menu.url} onClick={()=>{dispatch({type:"set",payload:menu.url})}} className={state.item===menu.url ? "menuburger menuactiveburger" :"menuburger"}> {menu.text}</Link>
                        </div>
                        
                    )
                })}
        </MenuBurger>
        </>
    );
}

export default Menu;