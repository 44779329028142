import React,{useState} from 'react';
import Title from '../Title';

import AutoSostitutiva from '../../img/AutoSostitutiva.jpg'
import AutoSostitutiva_logo from '../../img/AutoSostiutiva_logo.png'
import Preventivi from '../../img/Preventivi.jpg'
import Preventivi_logo from '../../img/Preventivi_logo.png'
import Riparazionecristalli from '../../img/Riparazione.jpg'
import Riparazionecristalli_logo from '../../img/Riparazionecristalli_logo.png'
import Soccorsostradale from '../../img/Soccorso ìStradale.jpeg'
import Soccorsostradale_logo from '../../img/Soccorsostradale_logo.png'

function Servizi(props) {
    const [autoSostitutiva, setAutoSostitutiva] = useState(false)
    const [riparazioneCristalli, setRiparazioneCristalli] = useState(false)
    const [preventivi, setPreventivi] = useState(false)
    const [soccorsoStradale, setSoccorsoStradale] = useState(false)
    return (
        <div className="container">
            <Title titolo={"SERVIZI"}></Title>
            <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12" onMouseEnter={()=>setPreventivi(!preventivi)} onMouseLeave={()=>setPreventivi(!preventivi)}>
                    <div style={{position:"relative"}}>
                        <img src={Preventivi} style={{width:"100%"}} alt="" srcset=""/>
                        <img hidden={!preventivi} src={Preventivi_logo} style={{width:"30%",zIndex:2,position:"absolute",top:"25%",left:"35%"}} alt="" srcset=""/>
                    </div>
                    <div style={{backgroundColor:"#be1622", padding:"15px 0", color:"white", fontSize:"20px"}} className="fontframe">
                        PREVENTIVI GRATUITI
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12" onMouseEnter={()=>setRiparazioneCristalli(!riparazioneCristalli)} onMouseLeave={()=>setRiparazioneCristalli(!riparazioneCristalli)}>
                    <div style={{position:"relative"}}>
                        <img src={Riparazionecristalli} style={{width:"100%"}} alt="" srcset=""/>
                        <img hidden={!riparazioneCristalli} src={Riparazionecristalli_logo} style={{width:"30%",zIndex:2,position:"absolute",top:"25%",left:"35%"}} alt="" srcset=""/>
                    </div>
                    <div style={{backgroundColor:"#be1622", padding:"15px 0", color:"white", fontSize:"20px"}} className="fontframe">
                        RIPARAZIONE CRISTALLI
                    </div>
                </div>
            </div>
            <div className="row" style={{marginTop:"15px"}}>
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12" onMouseEnter={()=>setAutoSostitutiva(!autoSostitutiva)} onMouseLeave={()=>setAutoSostitutiva(!autoSostitutiva)}>
                    <div style={{position:"relative"}}>
                        <img src={AutoSostitutiva} style={{width:"100%"}} alt="" srcset=""/>
                        <img hidden={!autoSostitutiva} src={AutoSostitutiva_logo} style={{width:"30%",zIndex:2,position:"absolute",top:"25%",left:"35%"}} alt="" srcset=""/>
                    </div>
                    <div style={{backgroundColor:"#be1622", padding:"15px 0", color:"white", fontSize:"20px"}} className="fontframe">
                        AUTO SOSTITUTIVA
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12" onMouseEnter={()=>setSoccorsoStradale(!soccorsoStradale)} onMouseLeave={()=>setSoccorsoStradale(!soccorsoStradale)}>
                    <div style={{position:"relative"}}>
                        <img src={Soccorsostradale} style={{width:"100%"}} alt="" srcset=""/>
                        <img hidden={!soccorsoStradale} src={Soccorsostradale_logo} style={{width:"30%",zIndex:2,position:"absolute",top:"25%",left:"35%"}} alt="" srcset=""/>
                    </div>
                    <div style={{backgroundColor:"#be1622", padding:"15px 0", color:"white", fontSize:"20px"}} className="fontframe">
                        SOCCORSO STRADALE
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Servizi;