import React from 'react';

function Frame(props) {
    return (
        <div style={{marginBottom:"5px",padding:"5px",margin:"0"}} className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
           <div style={{backgroundColor:props.backgroundcolor,width: "100%",paddingBottom: "25%"}}>
                <div style={{fontWeight:"bold",color:"black",fontSize:"18px",paddingTop:"15px"}} className="fontframe">{props.titolo}</div>
                <img src={props.immagine} style={{width:"90px",marginTop:"30px"}} alt=""/>
            </div>
        </div>
    );
}

export default Frame;   