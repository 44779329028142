import React,{useState} from 'react';
import Title from '../Title';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
 

const images = [
    '/immagini/Galleria_1.jpg',
    '/immagini/Galleria_2.jpg',
    '/immagini/Galleria_3.jpg',
    '/immagini/Galleria_4.jpg',
    '/immagini/Galleria_5.jpg',
    '/immagini/Galleria_6.jpg',
    '/immagini/Galleria_7.jpg',
    '/immagini/Galleria_8.jpg',
  ];

function Gallery(props) {
    const [isOpen, setIsOpen] = useState(false)
    const [photoIndex, setphotoIndex] = useState(0)
    return (
        <>
        <div className="container">
            <Title titolo={"GALLERY"}></Title>
            <div className="row">
                <div style={{marginTop:"15px"}} onClick={()=>{setphotoIndex(0);setIsOpen(true);}} className="col-lg-6 col-md-6 col-sm-6 col-xs-12"><img src="/immagini/Galleria_1.jpg" style={{width:"100%"}} alt="" srcset=""/></div>
                <div style={{marginTop:"15px"}} onClick={()=>{setphotoIndex(1);setIsOpen(true);}} className="col-lg-6 col-md-6 col-sm-6 col-xs-12"><img src="/immagini/Galleria_2.jpg" style={{width:"100%"}} alt="" srcset=""/></div>
            </div>
            <div className="row">
                <div style={{marginTop:"15px"}} onClick={()=>{setphotoIndex(2);setIsOpen(true);}} className="col-lg-6 col-md-6 col-sm-6 col-xs-12"><img src="/immagini/Galleria_3.jpg" style={{width:"100%"}} alt="" srcset=""/></div>
                <div style={{marginTop:"15px"}} onClick={()=>{setphotoIndex(3);setIsOpen(true);}} className="col-lg-6 col-md-6 col-sm-6 col-xs-12"><img src="/immagini/Galleria_4.jpg" style={{width:"100%"}} alt="" srcset=""/></div>
            </div>

        </div>
        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() =>
              setphotoIndex((photoIndex + images.length - 1) % images.length)
            }
            onMoveNextRequest={() =>
                setphotoIndex((photoIndex + 1) % images.length)
            }
          />
        )}
        </>
    );
}

export default Gallery;