import React from 'react';
import logo from '../img/logo.png'

function Header(props) {
    return (
        <div className="container">
            <div className="row">
                <div className="d-block d-sm-none col-2">
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-10">
                    <a href="https://www.revolution-car.it/"><img src={logo} alt="" style={{width:"100%",maxHeight:"100px",paddingTop:"10px",paddingBottom:"5px"}} /></a>

                </div>
            </div>
        </div>
    );
}

export default Header;