import React from 'react';

function Footer(props) {
    return (
        <div id="footer">
        <b>Revolution Car S.r.l.</b> - Via Piemonte, 6C - Loc. Po' Bandino - 06062 Città della Pieve (PG) -Tel. 0578226777 - <a href="mailto:info@revolution-car.it">info@revolution-car.it</a> P.iva e C.F.: 03692800547
        </div>
    );
}

export default Footer;  