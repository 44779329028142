import React from 'react';
import Title from '../Title';
import pin from '../../img/pin.png'
import Contattilabel from '../Contattilabel';

function Contatti(props) {
    return (
        <div className="container">
        <Title titolo={"DOVE SIAMO"}></Title>
        <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6-col-xs-12">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2917.7636077254315!2d11.975740815476813!3d43.00431197914899!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13294e0094029aa1%3A0x9c51b8514042769c!2sVia%20Piemonte%2C%206%2C%2006062%20Citt%C3%A0%20della%20Pieve%20PG!5e0!3m2!1sit!2sit!4v1579294389787!5m2!1sit!2sit" frameborder="0" style={{border:"0",width:"100%",height:"250px"}} allowfullscreen=""></iframe>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6-col-xs-12" style={{textAlign:"left"}}>
                <img src={pin} style={{height:"70px"}} alt=""/> <br/>

                <div style={{fontSize:"20px"}}>
                    Via Piemonte, 6C - Loc. Po' Bandino <br/>
                    06062, Città della Pieve(PG)
                </div>
            </div>
        </div>
        <Title titolo={"CONTATTI"}></Title>
        <div className="row" style={{fontWeight:"bold",margin:"5px"}}>
            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                <Contattilabel tipo={"left"} des={"Tel."} icon={"icn"} cnt={"0578226777"} backgroundcolor="#cccabb"></Contattilabel>
                <Contattilabel tipo={"left"} des={"Dario"} icon={"icn"} cnt={"349 7176151"} backgroundcolor="#e0dfdd"></Contattilabel>
                <Contattilabel tipo={"left"} des={"Lorenzo"} icon={"icn"} cnt={"349 1865605"} backgroundcolor="#cccabb"></Contattilabel>
                <Contattilabel tipo={"left"} icon={"icn"} cnt={<a style={{color:"black"}} href="mailto:info@revolution-car.it">info@revolution-car.it</a> } backgroundcolor="#e0dfdd"></Contattilabel>
            </div>
            <div className="col-lg-3 col-md-3 "></div>
            <div className="col-lg-5 col-md-5 col-sm-6 col-xs-12">
                <Contattilabel tipo={"right"} des={"Lun - Ven"} cnt={"8:30 - 13:00 14:30 - 18:30"}></Contattilabel>
                <Contattilabel tipo={"right"} des={"Sabato"} cnt={"8:30 - 13:00"}></Contattilabel>
                <Contattilabel tipo={"right"} des={"Domenica"} cnt={"Chiuso"}></Contattilabel>
            </div>
        </div>
        </div>
    );
}

export default Contatti;