import React from 'react';
import Title from '../Title';
import Riparazionecristalli_logo from "../../img/Riparazionecristalli_logo.png"
import AutoSostiutiva_logo from "../../img/AutoSostiutiva_logo.png"
import Preventivi_logo from "../../img/Preventivi_logo.png"
import Soccorsostradale_logo from "../../img/Soccorsostradale_logo.png"
import Frame from '../Frame';


function Carrozzeria(props) {
    return (
        <>
        <div className="container">
            <Title titolo={"CARROZZERIA REVOLUTION CAR"}></Title>
            <p style={{fontSize:"20px"}}>
                Con <b>esperienza</b> e <b>professionalità</b> offriamo ai nostri Clienti servizi di alto livello a prezzi contenuti. <br/>
                Ci troviamo a Po' Bandino in provincia di Perugia.
            </p>
            <div>
                <img src="/immagini/Carrozzeria.jpg" style={{width:"100%"}} alt=""/>
            </div>
            </div>
            <div className="container" style={{padding:"0"}}>
                <div className="row" style={{margin:"15px"}}>
                    <Frame titolo={"PREVENTIVI GRATUITI"} immagine={Preventivi_logo} backgroundcolor={"#cccabb"}></Frame>
                    <Frame titolo={"AUTO SOSTITUTIVA"} immagine={AutoSostiutiva_logo} backgroundcolor={"#e0dfdd"}></Frame>
                    <Frame titolo={"SOCCORSO STRADALE"} immagine={Soccorsostradale_logo} backgroundcolor={"#cccabb"}></Frame>
                    <Frame titolo={"RIPARAZIONE CRISTALLI"} immagine={Riparazionecristalli_logo} backgroundcolor={"#e0dfdd"}></Frame>
                </div>
            </div>
        </>
    );
}

export default Carrozzeria;