import React from 'react';

function Title(props) {
    return (
        <div className="fonttitle">
            <h1 style={{margin:"15px"}}>{props.titolo}</h1>
        </div>
    );
}

export default Title;