import React from 'react';

function Contattilabel(props) {
    return (
        <div>
            {props.tipo === "left" ? 
            <div style={{color:"black",backgroundColor:props.backgroundcolor,padding:"5px",marginTop:"5px"}} className="row">
                <div style={{textAlign:"right"}} className="col-2">{props.icon}</div>
                {props.des ? <>
                    <div className="col-4">{props.des}</div>
                    <div className="col-5">{props.cnt}</div>
                </> : 
                <div className="col-9">{props.cnt}</div>
                }
            </div> 
            :
            <div style={{color:"black",marginTop:"5px"}} className="row">
                <div className="col-4" style={{backgroundColor:"#cccabb",padding:"5px"}}>{props.des}</div>
                <div className="col-7" style={{marginLeft:"5px",padding:"5px",backgroundColor:"#e0dfdd"}}>{props.cnt}</div>
            </div> 
            }
        </div>
    );
}

export default Contattilabel;