import React from 'react';
import './App.css';
import Menu from './component/Menu';
import Header from './component/Header';
import Footer from './component/Footer';
import './component/styles.css'
import { MenuProvider } from './component/ContextProvider/MenuContext';

function App(props) {
  return (
    <div className="App">
      <div class="container-fluid">
        <div style={{position:"fixed",top:0,right:0,left:0,backgroundColor:"white",zIndex:1000}}>
          <Header></Header>
          <MenuProvider>
            <Menu></Menu>
          </MenuProvider>
        </div>
        <div id="childrenprops">
          {props.children}
        </div>
        <Footer></Footer>
      </div>
    </div>
  );
}

export default App;
