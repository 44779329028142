import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {
    BrowserRouter as Router,
    Switch,
    Route
  } from "react-router-dom";
import Gallery from './component/pages/Gallery';
import Carrozzeria from './component/pages/Carrozzeria';
import Contatti from './component/pages/Contatti';
import Servizi from './component/pages/Servizi';
import Landing from './component/pages/Landing';


ReactDOM.render(
<Router>
    <Switch>
        {/* <Route path="/" exact ><Landing></Landing></Route> */}
        <Route path="/" exact ><App><Carrozzeria></Carrozzeria></App></Route>
        <Route path="/gallery" exact ><App><Gallery></Gallery></App></Route>
        <Route path="/contatti" exact ><App><Contatti></Contatti></App></Route>
        <Route path="/servizi" exact ><App><Servizi></Servizi></App></Route>
    </Switch>
</Router>
, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
